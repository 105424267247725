import React from 'react'
import SectionWrapper from '../General/SectionWrapper'
import Portfolio from '../Home/Portfolio'

export default function PortfolioList({ linkPrefix = 'portfolio', serviceId = '', sortKey = "order", portfolioList = [], serviceList = [], sectionId = 'portfolio', sectionStyles = {}, }) {

    return <SectionWrapper sectionId='portfolio' >
        <Portfolio
            showTitle={false}             
            clipList={false}
            listSm={4}
            listMd={3}
            serviceId={serviceId}
            sortKey={sortKey}
            // expandBrandingCards={false}
        />
    </SectionWrapper >
}

