import React, {useState, useEffect} from 'react'
import Layout from '../components/Layout'
import Banner from '../components/Banner'
import { graphql } from 'gatsby'
import PortfolioList from '../components/Portfolio/PortfolioList'
import TabComponent from '../components/General/TabComponent'
import { portfolioServices } from '../utitlities/Configurations'
import { fetchData } from '../utitlities/helperFunctions'

const PortfolioPage = ({ data }) => {
  const [ServiceData,SetServiceData] = useState()
  const [PortfolioData,SetData] = useState()    
  useEffect(() => { 
     fetchData(`services`) 
     .then(x => SetServiceData(x) ) 
     fetchData(`portfolio`)
     .then(x => SetData(x) )        
  }, []);

  const portfolioJson = PortfolioData ? PortfolioData : data.portfolio.edges[0].node
  const serviceJson = ServiceData ? ServiceData : data.services.edges[0].node
  const bannerImageData = {url: data.bannerImage.edges[0].node.childImageSharp.original.src}
  const pageSeo = portfolioJson.Seo
   
  let finalServiceList = serviceJson.links.filter(item => portfolioServices.includes(item.id)).map(item => ({ value: item.id, label: item.shortTitle }))
  const [serviceId, setServiceId] = React.useState(finalServiceList.length === 0 ? "" : finalServiceList[0].value)
  const [selectedService, setSelectedService] = React.useState(finalServiceList.length === 0 ? "" : finalServiceList[0])
   
 
  return (
    <Layout pageSeo={pageSeo}>
      <Banner
        bannerId="portfolio-banner"
        heading={portfolioJson.heading}
        subHeading={portfolioJson.subHeading}
        secondaryHeadings={portfolioJson.headingHighlights}
        imageUrl={true}
        imageComponent={bannerImageData} />
      <TabComponent
        list={finalServiceList}
        value={serviceId}
        label={selectedService.label}
        onChange={(value) => {
          setServiceId(value)
          setSelectedService(finalServiceList.filter(item => item.value === value)[0])
        }}
      />
      <div style={{ paddingTop: '1rem' }}>
        <PortfolioList           
          linkPrefix='portfolio'
          serviceList={serviceJson.links}
          serviceId={serviceId}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
query PortfolioPageQuery {
    portfolio:allApiPortfolio {
      edges {
        node {
           Seo{
                title
                description
              }
              heading
              headingHighlights
              subHeading            
              
        }
      }
    }
    bannerImage: allFile(
      filter: {sourceInstanceName: {eq: "images"}, name: {eq: "PortfolioBanner"}}
    ) {
      edges {
        node {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
    services: allApiServices {
      edges {
        node {
          
          links {
                  slug
                  title
                  shortTitle
                  id
                  order
                }
        }
      }
    }
  }
`

export default PortfolioPage
